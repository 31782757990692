.select {
    color: #121212;
    /* width: 100%; */
}

.select__control {
    background-color: #121212 !important;
    border: 0 !important;
}

.select__placeholder {
    color: white !important;
}

.select__dropdown-indicator {
    color: var(--cd-green) !important;
}

.select__indicator-separator {
    display: none;
}

.select__menu {
    background-color: var(--cd-black) !important;
    color: white;
}

.select__option--is-focused {
    background-color: var(--cd-green) !important;
    color: var(--cd-black) !important;
}

.select__input {
    color: white !important;
}

.fulltext-input {
    height: 30px;
    /* width: 100%; */
    text-align: center;
    background-color: var(--cd-black);
    border: 2px solid var(--cd-green);
    border-radius: 20px;
    color: white;
    font-family: inherit;
}

.fulltext-input::placeholder {
    color: white;
}

button {
    border-color: var(--cd-green);
    color: white;
    border: 2px solid var(--cd-green);
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 1em;
    cursor: pointer;
    background-color: var(--cd-black);
    font-family: 'Euclid Circular A', Helvetica, Arial, Lucida, sans-serif;
}

button:disabled {
    color: #999;
}

input[type="number"] {
    border-color: var(--cd-green);
    color: white;
    border: 2px solid var(--cd-green);
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 1em;
    background-color: var(--cd-black);
}